import Web3 from "web3";
import detectEthereumProvider from "@metamask/detect-provider";

const web3 = new Web3();
let account;
let connected = false;
let chainId;
let provider;

window.convertToHex = (str) => {
    console.log('in conversion', this);
    return web3.utils.stringToHex(str);
}

 window.connectToEtherWallet = async () => {
    if (window.ethereum){
      try {
          console.log("MetaMask is installed!");
          const ethereum = window.ethereum;
          provider = await detectEthereumProvider();
          if (provider !== window.ethereum){
            throw new Error('Provider mismatch')
          }
          console.log('provider', provider);
          const accounts = await ethereum.request({
            method: "eth_requestAccounts",
          });
          account = accounts[0];
          connected = true;
          return true;
      } catch(e){
        console.error("Error connecting", e);
        return false;
      }
        // listenToAccountChanges();
    }
    console.log("No metamask");
    return false;
}

window.sendPaymentCompetitionRequest = async (memo, to, value) => {
    web3.setProvider(window.ethereum);
    console.log('window.ethereum after setting', window.ethereum);
    const gasPrice = await web3.eth.getGasPrice();
    const receipt = await web3.eth.sendTransaction({
        to,
        from: account,
        gasPrice,
        gas: 21000,
        value,
        data: memo
    });
    return receipt;
}

function listenToAccountChanges(){
    if (window.ethereum){
        window.ethereum.on('accountsChanged', (accounts) => {
            if (accounts){
                account = accounts[0];
            }
        });
    }
}

function listenToChainId(){
    if (window.ethereum){
        ethereum.on('chainChanged', updatedChainId => chainId = updatedChainId);
    }
}

listenToAccountChanges();
listenToChainId();
